<template>
  <v-alert v-if="!store.isAuthenticated && !store.userLoading" type="info" text>
    <v-row>
      <v-col>
        Get started with AwesomeTechStack for free. Sign up now to access all of
        our services.
      </v-col>
      <v-col cols="auto">
        <sign-up-btn size="large" color="primary"></sign-up-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script setup>
import { useStore } from '@/composables/store'
const store = useStore()
</script>
