<template>
  <v-alert v-if="store.user && store.user.usage" type="error" text>
    {{ $t('Alert402.message') }}
    <nuxt-link :to="{ name: 'user-subscription' }">{{
      $t('Alert402.linkText')
    }}</nuxt-link>
    {{ $t('Alert402.message2') }}
  </v-alert>
</template>

<script setup>
import { useStore } from '@/composables/store'
const store = useStore()
</script>
